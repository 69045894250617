body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #232323;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/fonts/Mont-Regular.ttf') format('ttf');
  src: url('./assets/fonts/Mont-Regular.woff') format('woff');
  src: url('./assets/fonts/Mont-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  src: url('./assets/fonts/Mont-SemiBold.ttf') format('ttf');
  src: url('./assets/fonts/Mont-SemiBold.woff') format('woff');
  src: url('./assets/fonts/Mont-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: 'Mont', sans-serif;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent
}

*::selection {
  background: #f4f7a168;
}

.app {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.container {
  width: 100%;
  margin: 0 auto;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}

@media (min-width: 480px) {
  .container {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 95%;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 1246px;
    margin: 0 auto;
  }

  .container--profile {
    max-width: 860px;
    margin: 0 auto;
  }
}

.mobile-container {
  width: 90%;
  margin: 0 auto;
}

.not-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.background-gradient {}

.background-gradient::before {
  width: 430px;
  height: 430px;
  flex-shrink: 0;
  position: absolute;
  left: -159px;
  top: 109px;
  border-radius: 100%;
  background: #573A34;
  opacity: 0.44;
  filter: blur(121px);
  z-index: -1;
}

.background-gradient::after {
  width: 430px;
  height: 430px;
  flex-shrink: 0;
  position: absolute;
  left: 211px;
  top: 434px;
  border-radius: 100%;
  background: #F4F7A1;
  opacity: 0.08;
  filter: blur(171px);
  z-index: -1;
}

.color-1 {
  color: #69F2D1 !important;
}

.color-2 {
  color: #6987F2 !important;
}

.color-3 {
  color: #8D69F2 !important;
}

.color-4 {
  color: #F2E569 !important;
}

.color-5 {
  color: #F28569 !important;
}

.moderator-color {
  color: #7DD060 !important;
}

* {
  scrollbar-color: #f2e4692d #252424 !important;
  scrollbar-width: thin !important;
}

*::-webkit-scrollbar {
  height: 3px;
  width: 7px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #f2e4692d;
  border-radius: 10px;
}

.no-backdrop-filter {
  backdrop-filter: none !important;
}

.info-step-1,
.info-step-2,
.info-step-4 {
  background: rgba(255, 255, 255, 0.03);
  padding: 10px 24px;
  border-radius: 16px;
  color: #FFFFFF !important;
  z-index: 10;
}

.info-step-4 {
  z-index: 10;
  position: relative;
}